import React, { Component } from 'react';
import { TranslatedBlock } from 'src/components/language';
import DownloadIcon from '../images/download-icon-blue.svg';
import './three-column-cards.scss';
import ReactGA from "react-ga4";
import CardOneImage from '../images/card_one_image.jpg';
import CardTwoImage from '../images/card_two_image.jpg';
import CardThreeImage from '../images/card_three_image.jpg';

import { AppStateContext } from 'src/contexts/app-state-provider.context'

  class threeColumnCards extends Component {
    contextType = AppStateContext;
    state = {
      isAudioVisible: false
    };
   
    trackDownloads(category,action){
      ReactGA.event({
        category,
        action
      });
    }
    render() {
     
  return (
    <section className="three-column-section threecolumn-variation">
          <div className="three-column-section">
            <div className="container">
              <div className="row">
                <div className=" col-md-4 col-xs-12 ">
                  <div className='image-on-card'> 
                    <TranslatedBlock language="english">
                      <a href="/pdf/Eye_Care_Professional_Discussion_Guide_NZ_M-NZ-00000719_MR9199.pdf" target="_blank" title="Eye Care Professional">
                        <img  srcSet={CardOneImage} alt="Eye Care Professional" className={'img-responsive'} />
                      </a>
                    </TranslatedBlock>
                  </div>
                  <div className='textonCard text-center'>
                    <h4><a href="/pdf/Eye_Care_Professional_Discussion_Guide_NZ_M-NZ-00000719_MR9199.pdf" title="Eye Care Professional Discussion Guide" target="_blank" className=''>Eye Care Professional Discussion Guide </a></h4>
                    <p>Helpful questions to discuss with your Eye Care Professional to better understand your condition and treatment options</p>
                    <a href="/pdf/Eye_Care_Professional_Discussion_Guide_NZ_M-NZ-00000719_MR9199.pdf" title="View Eye Care Discussion Guide" target="_blank" className='yellow-buttons'>
                     View Eye Care Discussion Guide</a>
                    
                  </div>
                </div>
                <div className=" col-md-4 col-xs-12 ">
                  <div className='image-on-card'>
                    <TranslatedBlock language="english">
                      <a href="/pdf/ROC00735_VABYSMO_Patient_Brochure-A5_WEB.pdf" target="_blank" title="treatment for wet AMD or DMO">
                      <img  srcSet={CardTwoImage} alt="treatment for wet AMD or DMO" className={'img-responsive'} />
                      </a>
                    </TranslatedBlock>
                  </div>
                  <div className='textonCard text-center'>
                    <h4><a href="/pdf/ROC00735_VABYSMO_Patient_Brochure-A5_WEB.pdf" target="_blank" className='' title="Considering Treatment with VABYSMO">Considering Treatment with VABYSMO?</a></h4>
                    <p>Written and visual information for patients considering VABYSMO as a treatment for wet AMD, DMO or RVO</p>
                    <a href="/pdf/ROC00735_VABYSMO_Patient_Brochure-A5_WEB.pdf" target="_blank" className='yellow-buttons' title="View Educational Brochure	">
                     
                      View Educational Brochure	</a>
                  </div>
                </div>
                <div className=" col-md-4 col-xs-12 ">
                  <div className='image-on-card'>
                    <TranslatedBlock language="english">
                      <a href="/pdf/VABYSMO_Patient_and_Caregiver_Guide.pdf" title="educational resource in print or audio"  onClick={ () => this.trackDownloads("user","patient_resources_pdf")} target="_blank">
                      <img  srcSet={CardThreeImage} alt="educational resource in print or audio" className={'img-responsive'} />
                      </a>
                    </TranslatedBlock>
                  </div> 
                  <div className='textonCard text-center'>
                    <h4><a href="/pdf/VABYSMO_Patient_and_Caregiver_Guide.pdf"  onClick={ () => this.trackDownloads("user","patient_resources_pdf")} target="_blank" className='' title="VABYSMO Patient & Caregiver Guide ">VABYSMO Patient & Caregiver Guide </a></h4>
                    <p>An educational resource in print or audio format to help you learn about starting treatment with VABYSMO</p>
                    <a href="/pdf/VABYSMO_Patient_and_Caregiver_Guide.pdf"  onClick={ () => this.trackDownloads("user","patient_resources_pdf")} target="_blank" className='yellow-buttons' title=" View Patient / Caregiver Guide (ENG)">
                        
                        View Patient / Caregiver Guide (ENG)	</a>
                      <a href="/pdf/Vabysmo_faricimab_patient_booklet_Simplified_Chinese_edition_AFD.pdf"  onClick={ () => this.trackDownloads("user","patient_resources_pdf")} target="_blank" className='download-paitent-brochure' title=" View Patient / Caregiver Guide [中文]">
                      <img srcSet={DownloadIcon} alt=" View Patient" className='donwload-image' />
                        View Patient / Caregiver Guide [中文]
                      </a>
                    
                      <button className="listen download-paitent-brochure" onClick={() => {
                            this.setState({ isAudioVisible: true });
                            this.trackDownloads("user","patient_resources_audio");
                          }
                        }>  <span>&#128362;</span> Listen to  Audio Version
                      </button>
                <TranslatedBlock language="english">
                {this.state.isAudioVisible ? (
                  <div className="audio-el">
                    {/* eslint-disable jsx-a11y/media-has-caption */}
                    <audio controls autoPlay src=" /audio/Vabysmo_Patient Booklet_audio guide_NZ.mp3">
                      Your browser does not support the
                      <code>audio</code> element.
                    </audio>
                    {/* eslint-enable jsx-a11y/media-has-caption */}
                  </div>
                ) : (
                  ''
                )}
              </TranslatedBlock>
                  </div>
                </div>
              </div>
            </div>   
          </div>
        </section>
       
      );
    }
  }

export default threeColumnCards;